<template>
  <div>
      <alter-form :customeButton="true" ref="alterform" :watchImageVisible="false" :modifyData="data" @formSubmit="formSubmit" :formData="formData"></alter-form>
  </div>
</template>

<script>
// 组件
import alterForm from '@/components/alterForm'
// 接口
import jsonData from '../jsonData'
import apis from '@/services/index'

export default {
  name: 'priority',
  components: {
    alterForm
  },
  props: ['data'],
  data () {
    return {
      modifyData: {},
      formData: jsonData.formData_priority
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    async init () {
      const userGroup = await apis.SynamicLoad.getUserGroupsSimpleList()
      this.formData[0].createDateFirstItem[1].options = userGroup.data.user_groups
    },
    formSubmit (data) {
      const obj = JSON.parse(JSON.stringify(data))
      this.$emit('tabChange', obj, 'priority')
    }
  }
}
</script>

<style lang="scss" scope>
</style>
