<template>
<div class="charging--edit">
    <el-card>
      <el-tabs v-model="activeName" :before-leave="beforeLeave">
        <el-tab-pane label="General" name="general"></el-tab-pane>
        <el-tab-pane label="User Priority" name="priority"></el-tab-pane>
      </el-tabs>
      <keep-alive>
        <component ref="form" :is="name[activeName]" :data="data[activeName]" @tabChange="tabChange"></component>
      </keep-alive>
    </el-card>
    <el-card>
      <div class="footer">
        <el-button @click="handleCancelClick">{{ $t('button.cancel') }}</el-button>
        <el-button type="primary" @click="handleSubmitClick">{{ $t('button.confirm') }}</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import generalVue from './component/general.vue'
import priorityVue from './component/priority.vue'
import apis from '@/services/index'
import loading from '@/utils/loading.json'
export default {
  name: 'tariffsEdit',
  components: { generalVue, priorityVue },
  data () {
    return {
      name: {
        general: generalVue,
        priority: priorityVue
      },
      activeName: 'general',
      data: {
        general: {},
        priority: {}
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    handleSubmitClick () {
      this.$store.state.app.loading = this.$loading(loading)
      this.beforeLeave()
      this.data.general.set_charger_limit_to_zero_when_idle = !!this.data.general.set_charger_limit_to_zero_when_idle
      const params = {
        circuit: Object.assign({}, this.data.general, this.data.priority)
      }
      let api = null
      const arr = []
      if (this.$route.params.id) {
        api = apis.SynamicLoad.getCircuitsEdit
        arr.push(this.$route.params.id)
      } else {
        api = apis.SynamicLoad.getCircuitsAdd
      }
      arr.push(params)
      api(...arr).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess'),
            onClose: () => { this.handleCancelClick() }
          })
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleCancelClick () {
      this.$router.go(-1)
    },
    tabChange (data, name) {
      this.data[name] = data
    },
    beforeLeave () {
      this.$refs.form.$refs.alterform.handleSubmitClick()
    },
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      if (this.$route.params.id) {
        const { data } = await apis.SynamicLoad.getCircuitsPreview(this.$route.params.id)
        this.data.general = {
          name: data.circuit.name,
          no_of_phases: data.circuit.no_of_phases,
          max_current_per_phase: data.circuit.max_current_per_phase,
          descrimax_current_per_phaseption: data.circuit.max_current_per_phase,
          min_current_per_charge_point: data.circuit.min_current_per_charge_point,
          set_charger_limit_to_zero_when_idle: data.circuit.set_charger_limit_to_zero_when_idle,
          charge_point_ids: data.circuit.charge_point_ids
        }
        this.data.priority = {
          circuit_priorities_attributes: data.circuit.circuit_priorities_attributes
        }
        // this.data.pricing = {
        //   tariff_type: data.tariff.tariff_type,
        //   connection_fee: data.tariff.connection_fee,
        //   price_per_kwh: data.tariff.price_per_kwh,
        //   duration_fee_time_period: data.tariff.duration_fee_time_period,
        //   fee_per_time_period: data.tariff.fee_per_time_period,
        //   duration_fee_grace_period: data.tariff.duration_fee_grace_period,
        //   idle_fee_per_minute: data.tariff.idle_fee_per_minute,
        //   idle_fee_grace_period: data.tariff.idle_fee_grace_period,
        //   pre_authorize_amount: data.tariff.pre_authorize_amount,
        //   min_price: data.tariff.min_price
        // }
        // this.data.restrictions = {
        //   users_of_cp_owner: data.tariff.users_of_cp_owner,
        //   users_of_all_roaming_partners: data.tariff.users_of_all_roaming_partners,
        //   ad_hoc_users: data.tariff.ad_hoc_users
        // }
        // this.data.stopSession = {
        //   session_max_time: data.tariff.session_max_time,
        //   energy_exceeds: data.tariff.energy_exceeds,
        //   stop_when_pre_authorization_amount_falls_below: data.tariff.stop_when_pre_authorization_amount_falls_below,
        //   minimum_balance_required_to_start_session: data.tariff.minimum_balance_required_to_start_session,
        //   automatically_stop_when_balance_fall_below: data.tariff.automatically_stop_when_balance_fall_below
        // }
      }
      this.$store.state.app.loading.close()
    }
  }
}
</script>
<style lang="scss" scoped>
.charging--edit {
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
