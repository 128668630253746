import i18n from '@/i18n/index'

export default {
  formData: [{
    span: 16,
    prop: 'name',
    name: 'input',
    label: i18n.t('name'),
    placeholder: i18n.t('name'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'no_of_phases',
    name: 'group-radio',
    options: [{
      value: 0,
      name: 'Single Phase'
    }, {
      value: 3,
      name: '3 Phase'
    }],
    label: i18n.t('No. of Phases'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'max_current_per_phase',
    name: 'input',
    label: i18n.t('Max Current(A) per phase'),
    placeholder: i18n.t('Max Current(A) per phase'),
    Object: {
    }
  }, {
    span: 16,
    prop: 'min_current_per_charge_point',
    name: 'input',
    label: i18n.t('Min Current(A) per charge point'),
    placeholder: i18n.t('Min Current(A) per charge point'),
    Object: {
      label: 'The min charge rate defines what is the lowest A allowed. New sessions cannot start if any of the existing ones has to be lowered to less than the set min charge rate. This is to ensure that a connected car can actually charge because the cars have a min charge rate below which they would not accept the charge, if left empty, there is no minimum.'
    }
  }, {
    span: 16,
    prop: 'set_charger_limit_to_zero_when_idle',
    name: 'checkbox',
    value: false,
    label: i18n.t('Set charger limit to zero when idle'),
    Object: {
      label: 'if the chasge point enters an idle period,this means 5min of no charging. we assume that the EV is charged and will set the limit of this charge point to 0.Hence,we will free up the energy for other EVs'
    }
  }, {
    span: 16,
    prop: 'charge_point_ids',
    name: 'select',
    options: [],
    label: i18n.t('Charge Points'),
    placeholder: i18n.t('Charge Points'),
    Object: {
      value: 'id',
      label: '',
      filterable: true,
      multiple: true
    }
  }],
  formData_priority: [{
    span: 24,
    prop: 'circuit_priorities_attributes',
    name: 'array',
    value: [],
    label: 'User Priority',
    Object: {
      delete: '_destroy',
      'label-width': '0'
    },
    createDateFirstItem: [{
      span: 16,
      prop: 'priority',
      name: 'input',
      type: 'number',
      label: i18n.t('Priority'),
      placeholder: i18n.t('Priority'),
      Object: {
      }
    }, {
      span: 16,
      prop: 'user_group_id',
      name: 'select',
      options: [],
      value: [],
      label: 'User Group',
      placeholder: i18n.t('User Group'),
      Object: {
        filterable: true,
        name: 'name',
        value: 'id'
      }
    }]
  }]
}
